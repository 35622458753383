@tailwind base;
@tailwind components;
@tailwind utilities;
html, body, #root {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-family: PingFang SC,Helvetica Neue,Helvetica,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;;
}

a {
  text-decoration: none;
}